import { ColorModeContext, useMode } from "./theme";
import { createContext, useContext, useState, useRef, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Query from "./scenes/query";
import ChatComponent from "./scenes/chat";
import Users from "./scenes/users";
import Contacts from "./scenes/contacts";
import Invoices from "./scenes/invoices";
import Form from "./scenes/form";
import Displays from "./scenes/dashboards";
import Login from "./components/Login";
import Blank from "./components/GetUser";
import { useImmer } from "use-immer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { UserContext } from "./Auth";
import { useNavigate } from "react-router-dom";
import FurnaceSchedule from "./components/customer/riotinto/FurnaceSchedule.jsx";
import createAxiosApi from "./lib/api.jsx";
import DataSourcesIframe from './scenes/data-sources/DataSourcesIframe.jsx';

export const AllTagsContext = createContext({});
export const FeatureFlagsContext = createContext({});
export const ApiContext = createContext({});
export const CollapsedContext = createContext({
  isCollapsed: false,
  setCollapsed: () => {},
});
export const DataSeriesListContext = createContext([]);
export const NotebookIntegrationsContext = createContext({});
export const LlmChatEndpointsContext = createContext({});

function App({ signout }) {
  const user = useContext(UserContext);
  const [theme, colorMode] = useMode();

  const [allTags, setAllTags] = useState([]);
  const navigate = useNavigate();
  const [api, setApi] = useState(() => createAxiosApi(signout));

  const [isCollapsedState, setIsCollapsed] = useState(false);

  const [notebookIntegrations, setNotebookIntegrations] = useState([]);
  const [llmChatEndpoints, setLlmChatEndpoints] = useState([]);
  const [dataSeriesList, setDataSeriesList] = useState(undefined);

  let contextRids = [];

  const fetchNotebookIntegrations = async () => {
    try {
      const response = await api.get(
        `/timeseries/notebook-integrations/${user.rid}`
      );
      if (response.status === 200 && response.data) {
        setNotebookIntegrations(response.data);
      } else {
        setNotebookIntegrations([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLlmChatEndpoints = async () => {
    try {
      const response = await api.get(`/llm_chat_endpoints/active`);
      if (response.data) {
        setLlmChatEndpoints([
          { rid: 0, name: "System Default" },
          ...response.data,
        ]);
      } else {
        setLlmChatEndpoints([{ rid: 0, name: "System Default" }]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //  Get list of available chat contexts
  useEffect(() => {
    fetchNotebookIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchLlmChatEndpoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api
      .get("/timeseries/tags", {
        params: {
          context_rid_list: contextRids,
        },
      })
      .then((response) => {
        setAllTags(response.data);
      })
      .catch((err) => {
        console.error("Failed to fetch all tags:", err);
      });
  }, []);

  useEffect(() => {
    api
      .get("/timeseries/dataseries", {
        params: {
          context_rid_list: contextRids,
        },
      })
      .then((response) => {
        setDataSeriesList(response.data);
      })
      .catch((err) => {
        console.error("Failed to fetch data series:", err);
      });
  }, []);

  const [featureFlags, setFeatureFlags] = useState({});

  useEffect(() => {
    api
      .get("/feature-flags")
      .then((response) => {
        setFeatureFlags(response.data);
      })
      .catch((err) => {
        console.error("Failed to retrieve feature flags:", err);
      });
  }, []);

  return (
    <NotebookIntegrationsContext.Provider value={notebookIntegrations}>
      <LlmChatEndpointsContext.Provider value={llmChatEndpoints}>
        <ApiContext.Provider value={api}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FeatureFlagsContext.Provider value={featureFlags}>
              <DataSeriesListContext.Provider value={dataSeriesList}>
                <AllTagsContext.Provider value={allTags}>
                  <ColorModeContext.Provider value={colorMode}>
                    <CollapsedContext.Provider
                      value={{
                        isCollapsed: isCollapsedState,
                        setCollapsed: (state) => {
                          setIsCollapsed(state);
                        },
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <div className="app">
                          <Sidebar signout={signout} />
                          <main
                            className="content"
                            style={{
                              position: "relative",
                              width: isCollapsedState
                                ? "calc(100dvw - 120px)"
                                : "calc(100dvw - 270px)",
                              left: isCollapsedState ? "85px" : "250px",
                              transition: "left 0.25s ease-out",
                            }}
                          >
                            <Routes>
                              <Route path="/" element={<ChatComponent />} />
                              <Route
                                path="/dashboards"
                                element={<Displays />}
                              />
                              <Route
                                path="/furnace"
                                element={<FurnaceSchedule />}
                              />
                              <Route path="/query" element={<Query />} />
                              <Route path="/users" element={<Users />} />
                              <Route path="/contacts" element={<Contacts />} />
                              <Route path="/invoices" element={<Invoices />} />
                              <Route path="/data-sources" element={<DataSourcesIframe />} />
                              <Route path="/form" element={<Form />} />
                              <Route
                                path="/login"
                                element={<Navigate to="/login" replace />}
                              />
                            </Routes>
                          </main>
                        </div>
                      </ThemeProvider>
                    </CollapsedContext.Provider>
                  </ColorModeContext.Provider>
                </AllTagsContext.Provider>
              </DataSeriesListContext.Provider>
            </FeatureFlagsContext.Provider>
          </LocalizationProvider>
        </ApiContext.Provider>
      </LlmChatEndpointsContext.Provider>
    </NotebookIntegrationsContext.Provider>
  );
}
export default App;
