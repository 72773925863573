// datalens-frontend/src/scenes/global/DataSourcesIframe.jsx
import React from 'react';

const DataSourcesIframe = () => {
  return (
    <iframe
      src="http://74.235.227.185/"
      style={{ width: '100%', height: '700%', border: 'none' }}
      title="Data Sources"
    />
  );
};

export default DataSourcesIframe;